<template>
  <div class="sell-kanban">
    <a-tabs v-model:activeKey="switchButton"
            @change="handleChangeTabs">
      <a-tab-pane key="realTimeSalesData"
                  tab="实时销售数据"
                  forceRender>
        <sell-real-time-data>
        </sell-real-time-data>
      </a-tab-pane>
      <a-tab-pane key="saleRank"
                  tab="销售排行">
        <div class="flex">
          <div class="table-sider flex-shrink-0 overflow-x-auto">
            <h3 class="mt-16 mb-8 ml-16">部门信息</h3>
            <a-menu mode="inline">
              <a-tree v-model:selectedKeys="selectedKeys"
                      v-if="treeData.length"
                      :tree-data="treeData"
                      :replaceFields="replaceDepartDataKey"
                      defaultExpandAll
                      show-icon
                      @select="selectDepart">
                <template #book>
                  <FolderFilled />
                </template>
                <template #title="{ workWechatDeptId }">
                  <div v-is="'ww-open-data'"
                       class="ww-open-data"
                       type="departmentName"
                       :openid="workWechatDeptId"></div>
                </template>
              </a-tree>
            </a-menu>
          </div>

          <div class="flex-1 ml-16">
            <a-select v-model:value="searchForm.dateInterval"
                      class="mb-12"
                      :options="lastDay"
                      @select="onSearchSubmit"
                      style="width: 160px;">
            </a-select>

            <a-table class="sell-rank__table"
                     :scroll="{  y: 'calc(100vh - 359px)' }"
                     :columns="columns"
                     :data-source="rank"
                     :pagination="pagination"
                     row-key="id"
                     :loading="tableLoading"
                     @change="onPaginationChange">
              <template #rank="{record}">
                <svg v-if="record.rank === 1"
                     class="icon"
                     aria-hidden="true">
                  <use xlink:href='#iconguanjun'></use>
                </svg>
                <svg v-if="record.rank === 2"
                     class="icon"
                     aria-hidden="true">
                  <use xlink:href='#iconyajun'></use>
                </svg>
                <svg v-if="record.rank === 3"
                     class="icon"
                     aria-hidden="true">
                  <use xlink:href='#iconjijun'></use>
                </svg>
                <span v-if="record.rank > 3">{{ record.rank}}</span>
              </template>

              <template #staff="{record}">
                <avatar-column :avatar="{avatar: record.staffAvatar, userId: record.staffName}"
                               :size="40"
                               round
                               is-user-id />
              </template>

              <template #departmentName="{ text }">
                <div v-is="'ww-open-data'"
                     class="ww-open-data"
                     type="departmentName"
                     :openid="text"></div>
              </template>

              <template #totalAmount="{record}">
                {{(record.totalAmount / 100).toLocaleString()}}
              </template>
            </a-table>
          </div>
        </div>

      </a-tab-pane>
    </a-tabs>

  </div>
</template>

<script>
import marketDataApi from "@/service/api/marketData";
import staffApi from "@/service/api/staff";
import { Tabs, Tree } from "ant-design-vue";
import { defineComponent, ref, reactive } from "vue";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import router from "@/router";
import SellRealTimeData from "./SellRealTimeData.vue";
import AvatarColumn from "@/components/AvatarColumn";
import { FolderFilled } from "@ant-design/icons-vue";

import { getWxAgentConfig } from "@/global";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "SellKanban",
  components: {
    SellRealTimeData,
    ATree: Tree,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AvatarColumn,
    FolderFilled,
  },

  data() {
    const fieldNames = {
      label: "title",
      value: "workWechatDeptId",
      children: "children",
    };
    const columns = [
      {
        title: "排名",
        dataIndex: "rank",
        align: "center",
        slots: { customRender: "rank" },
      },
      {
        title: "员工",
        dataIndex: "staff",
        slots: { customRender: "staff" },
      },
      {
        title: "部门",
        dataIndex: "departmentName",
        slots: { customRender: "departmentName" },
      },
      {
        title: "订单数",
        dataIndex: "orderNum",
      },
      {
        title: "订单金额",
        dataIndex: "totalAmount",
        slots: { customRender: "totalAmount" },
      },
    ];
    const lastDay = [
      { value: "day", label: "今日" },
      { value: "week", label: "近7天" },
      { value: "month", label: "近30天" },
    ];

    const replaceDepartDataKey = {
      children: "children",
      title: "title",
      key: "workWechatDeptId",
    };

    return {
      columns,
      lastDay,
      fieldNames,

      datetime: "day",

      replaceDepartDataKey,
    };
  },
  setup: function () {
    const route = new useRoute();

    const switchButton = ref("realTimeSalesData");
    if (route.query.active) {
      switchButton.value = route.query.active;
    }

    const searchForm = reactive({
      dateInterval: "day",
      departmentId: 1,
    });

    // const deptList = ref([]);
    const departmentList = ref([]);
    const selectedKeys = ref([]);
    const treeData = ref([]);

    const iterateToAddIcons = (obj) => {
      for (var name in obj) {
        if (typeof obj[name] === "object") {
          obj["slots"] = { icon: "book" };

          iterateToAddIcons(obj[name]);
        } else {
          obj["slots"] = { icon: "book" };
        }
      }
      return obj;
    };

    async function getAllDepartments() {
      const res = await staffApi.getAllDepartments();
      treeData.value = iterateToAddIcons(res);

      // departmentList.value = [deptList.value[0].id];
      searchForm.departmentId = treeData.value[0].workWechatDeptId;
      selectedKeys.value = [treeData.value[0].workWechatDeptId];
      searchForm.dateInterval = "day";

      fetchPaginationData();
    }

    getAllDepartments();

    const selectDepart = (res) => {
      searchForm.departmentId = res[res.length - 1];
      onSearchSubmit();
    };

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(
      router,
      searchForm,
      marketDataApi.getOrderAmountRank
    );

    getWxAgentConfig();

    const handleChangeTabs = (activeKey) => {
      console.log(activeKey);
      router.push({
        name: route.name,
        query: { ...route.query, active: activeKey },
      });
    };

    return {
      switchButton,

      searchForm,
      treeData,
      departmentList,
      selectedKeys,
      getAllDepartments,

      rank: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,

      selectDepart,
      // selectDataTime,
      handleChangeTabs,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.anticon-caret-down.ant-tree-switcher-icon),
:deep(.ant-tree-iconEle.ant-tree-icon__customize) {
  color: @color-primary;
}
</style>